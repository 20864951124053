import { useQuery } from "@tanstack/react-query";
import React from "react";
import { MenuItem, Select } from "@material-ui/core";
import AdminService from "../../../services/AdminService";

export default function ClientsUserSelect({ companyId, userId, onChange }) {
  const { isLoading, data } = useQuery({
    queryKey: ["company_users_simplified", { companyId }],
    queryFn: () => AdminService.getAllUsersSimplified(companyId),
    enabled: !!companyId
  });

  const handleChange = e => {
    onChange(e.target.value);
  };

  if (isLoading) return <div>Chargement des utilisateurs...</div>;

  if (data && data.length > 0) {
    return (
      <Select
        value={userId}
        onChange={handleChange}
        displayEmpty
        variant="outlined"
        fullWidth
        style={{ textAlign: "left" }}
        placeholder=""
      >
        <MenuItem value="" disabled>
          <em> -- Sélectionnez un utilisateur -- </em>
        </MenuItem>
        {data.map(({ id, firstname, lastname }) => (
          <MenuItem key={id} value={id}>
            {firstname} {lastname}
          </MenuItem>
        ))}
      </Select>
    );
  }

  return <div>Aucun utilisateur trouvé</div>;
}
