import React, { useEffect, useState } from "react";
import { TableCell, TableRow } from "@material-ui/core";
import { ClientSchema } from "../../../services/ClientService";

export default function ClientLine({ client }) {
  const [errors, setErrors] = useState([]);
  const { businessName, firstname, lastname, email, phone, phone2, address, zipCode, city } = client;

  // get validation errors
  const getErrors = async () => {
    try {
      await ClientSchema().validateSync(client, { abortEarly: false });
      setErrors([]);
    } catch (error) {
      console.log(error);
      setErrors(error.inner);
    }
  };

  useEffect(() => {
    getErrors();
  }, [client]);

  return (
    <TableRow style={{ backgroundColor: errors.length > 0 ? "red" : "white" }}>
      <TableCell>{businessName}</TableCell>
      <TableCell>{lastname}</TableCell>
      <TableCell>{firstname}</TableCell>
      <TableCell>{email}</TableCell>
      <TableCell>{phone}</TableCell>
      <TableCell>{phone2}</TableCell>
      <TableCell>{address}</TableCell>
      <TableCell>{zipCode}</TableCell>
      <TableCell>{city}</TableCell>
    </TableRow>
  );
}
