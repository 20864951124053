import React from "react";
import { Button, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useSnackbar } from "notistack";
import Papa from "papaparse";

import CompaniesSelect from "../../components/admin/CompaniesSelect";
import UploadFileButton from "../../components/button/UploadFileButton";
import AdminService from "../../services/AdminService";
import ClientLine from "../../components/admin/import/ClientLine";
import ClientsUserSelect from "../../components/admin/import/ClientsUserSelect";

const useStyles = makeStyles(theme => ({
  container: { padding: theme.spacing(2) },
  card: {
    minHeight: 120,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(),
    "&:hover": {
      cursor: "pointer"
    }
  },
  cardNoClick: {
    minHeight: 120,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing()
  },
  cardIcon: { flex: 2, color: "#3C3C3C" }
}));

export default function SuperAdminImportClientsPage() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [companyId, setCompanyId] = React.useState("");
  const [userId, setUserId] = React.useState("");
  const [file, setFile] = React.useState();
  const [clients, setClients] = React.useState([]);

  const handleCompanyChange = _company => {
    setCompanyId(_company.id);
    setUserId("");
  };

  const handleChange = e => {
    setFile({ content: e.file, id: e.id });
    Papa.parse(e.file, {
      header: true,
      skipEmptyLines: true,
      complete(results) {
        const mappedClients = results.data
          .map((client, index) => {
            const _phone = client.phone && client.phone.length === 9 ? `0${client.phone}` : client.phone || "";
            const _phone2 = client.phone2 && client.phone2.length === 9 ? `0${client.phone2}` : client.phone2 || "";
            return { ...client, index, phone: _phone, phone2: _phone2 };
          })
          .sort((a, b) => a.lastname.localeCompare(b.lastname));

        setClients(mappedClients);
      }
    });
  };

  const handleSubmit = () => {
    AdminService.addContacts(companyId, userId, clients)
      .then(() => {
        enqueueSnackbar("Import OK", { variant: "success" });
      })
      .catch(() => {
        enqueueSnackbar("Import KO", { variant: "error" });
      });
  };

  return (
    <div>
      <Grid container className={classes.container} spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4">Import d&apos;un annuaire</Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper style={{ padding: 16 }}>
            <CompaniesSelect onChange={handleCompanyChange} selectedValue={companyId} />
          </Paper>
        </Grid>
        {companyId && (
          <Grid item xs={12}>
            <Paper style={{ padding: 16, textAlign: "center" }}>
              <UploadFileButton
                accept=".csv"
                id="file"
                label={file && file.content && file.content.name ? file.content.name : "Choisir un fichier"}
                onChange={handleChange}
              />
            </Paper>
          </Grid>
        )}
        {file && clients.length === 0 && (
          <Grid item xs={12}>
            <Paper style={{ padding: 16, textAlign: "center" }}>
              <Typography>Aucun client</Typography>
            </Paper>
          </Grid>
        )}
        {file && clients.length > 0 && (
          <Grid item xs={12}>
            <Paper style={{ padding: 16, textAlign: "center" }}>
              <Typography>Clients : {clients.length}</Typography>
              <Table size="small" className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>Entreprise</TableCell>
                    <TableCell>Nom</TableCell>
                    <TableCell>Prénom</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Téléphone portable</TableCell>
                    <TableCell>Téléphone fixe</TableCell>
                    <TableCell>Adresse</TableCell>
                    <TableCell>Code postal</TableCell>
                    <TableCell>Ville</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {clients.map((client, index) => (
                    <ClientLine client={client} key={`client-${index + 1}`} />
                  ))}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        )}
        {companyId && (
          <Grid item xs={12}>
            <Paper style={{ padding: 16 }}>
              <ClientsUserSelect companyId={companyId} userId={userId} onChange={setUserId} />
            </Paper>
          </Grid>
        )}
        {file && clients.length > 0 && userId && (
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Valider
            </Button>
          </Grid>
        )}
      </Grid>
    </div>
  );
}
