import ColorCompany from "./components/company/ColorCompany";
import GeneralTerms from "./components/company/GeneralTerms";
import Info from "./components/company/Info";
import LostPassPage from "./pages/LostPassPage";
import NewOrderFormPage from "./pages/NewOrderFormPage";
import OrderFormPage from "./pages/OrderFormPage";
import ProductListingPage from "./pages/ProductListingPage";
import ProfilPage from "./pages/ProfilPage";
import SellerPage from "./pages/SellerPage";
import SignInPage from "./pages/SignInPage";
import Withdrawal from "./components/company/Withdrawal";
import InvoicesPage from "./pages/InvoicesPage";
import InvoicePage from "./pages/InvoicePage";
import HomePage from "./pages/HomePage";
import DashboardPage from "./pages/DashboardPage";
import Logos from "./components/company/Logos";
import ClientsPage from "./pages/ClientsPage";
import ClientPage from "./pages/ClientPage";
import EstimatesPage from "./pages/EstimatesPage";
import EstimatePage from "./pages/EstimatePage";
import PaymentsPage from "./pages/PaymentsPage";
import PublicSignaturePage from "./pages/PublicSignaturePage";
import PlanPage from "./pages/PlanPage";
import ProfilePage from "./pages/ProfilePage";
import SuperAdminHomePage from "./pages/superAdmin/SuperAdminHomePage";
import SuperAdminCompaniesPage from "./pages/superAdmin/SuperAdminCompaniesPage";
import SuperAdminUsersPage from "./pages/superAdmin/SuperAdminUsersPage";
import SuperAdminUserPage from "./pages/superAdmin/SuperAdminUserPage";
import SuperAdminLogsPage from "./pages/superAdmin/SuperAdminLogsPage";
import SuperAdminManageCompanyPage from "./pages/superAdmin/SuperAdminManageCompanyPage";
import SuperAdminAddCompanyPage from "./pages/superAdmin/SuperAdminAddCompanyPage";
import SuperAdminEditCompanyPage from "./pages/superAdmin/SuperAdminEditCompanyPage";
import EditClientPage from "./pages/EditClientPage";
import SignaturesPage from "./pages/SignaturesPage";
import SignaturePage from "./pages/SignaturePage";
import SignatoryOTPSignaturePage from "./pages/SignatoryOTPSignaturePage";
import ActivationPage from "./pages/ActivationPage";
// import Financing from "./components/company/Financing";
import PaymentPage from "./pages/PaymentPage";
import PaymentSettings from "./components/company/PaymentSettings";
import PaymentVariant from "./components/company/PaymentVariant";
import YousignSignaturePage from "./pages/YousignSignaturePage";
import SuperAdminHiddenPage from "./pages/superAdmin/SuperAdminHiddenPage";
import SuperAdminImportCatalogPage from "./pages/superAdmin/SuperAdminImportCatalogPage";
import ProductCategoryListingPage from "./pages/ProductCategoryListingPage";
import AdditionalDocumentsPage from "./pages/additionalDocuments/AdditionalDocumentsPage";
import AdditionalDocumentPage from "./pages/additionalDocuments/AdditionalDocumentPage";
import SuperAdminReplicateCatalogPage from "./pages/superAdmin/SuperAdminReplicateCatalogPage";
import SuperAdminDeleteCatalogPage from "./pages/superAdmin/SuperAdminDeleteCatalogPage";
import SuperAdminImportClientsPage from "./pages/superAdmin/SuperAdminImportClientsPage";

export const disconnected = {
  signin: {
    path: "/connexion",
    component: SignInPage,
    text: "Connexion",
    icon: "default"
  },
  lostpass: {
    path: "/mot-de-passe-oublie",
    component: LostPassPage,
    text: "Mot de passe oublié",
    icon: "default"
  },
  activation: {
    path: "/activation/:token",
    component: ActivationPage,
    text: "Activation du compte",
    icon: "default"
  },
  signature: {
    path: "/sign/:companyId/:estimateId",
    component: PublicSignaturePage,
    text: "Signature"
  },
  yousignSignature: {
    path: "/sign_v2/:companyId/:contentId/:signatureRequestId/:signatoryId",
    component: YousignSignaturePage,
    text: "Signature"
  },
  signatorySignature: {
    path: "/doc-signatures/:signatureId/signatories/:signatoryId",
    component: SignatoryOTPSignaturePage,
    text: "Signature"
  }
};

export const privateRoutes = {
  common: {
    home: {
      path: "/",
      component: HomePage,
      text: "Homepage",
      icon: "home",
      hideInMenu: false
    },
    account: {
      path: "/compte",
      component: ProfilPage,
      text: "Mon compte",
      icon: "account",
      hideInMenu: false
    },
    clients: {
      path: "/clients",
      component: ClientsPage,
      text: "Clients",
      hideInMenu: false
    },
    clientDetails: {
      path: "/clients/:clientId",
      component: ClientPage,
      text: "Client",
      hideInMenu: true
    },
    editClientDetails: {
      path: "/clients/:clientId/modification",
      component: EditClientPage,
      text: "Modifier un client",
      hideInMenu: true
    },
    estimates: {
      creationForm: {
        path: "/nouveau-devis",
        component: EstimatePage,
        text: "Nouveau devis",
        icon: "order",
        hideInMenu: false
      },
      editionForm: {
        path: "/modification-devis/:estimateId",
        component: EstimatePage,
        text: "Modification devis",
        icon: "order",
        hideInMenu: false
      }
    },
    orderForms: {
      creationForm: {
        path: "/nouveau-bon",
        component: NewOrderFormPage,
        text: "Nouveau bon",
        icon: "edit",
        hideInMenu: false
      }
    },
    additionalDocuments: {
      worksAcceptanceListing: {
        path: "/documents-additionnels",
        component: AdditionalDocumentsPage,
        text: "Documents additionnels",
        icon: "order",
        hideInMenu: false
      },
      worksAcceptanceDetails: {
        path: "/documents-additionnels/:additionalDocumentId",
        component: AdditionalDocumentPage,
        text: "Document additionnel",
        icon: "order",
        hideInMenu: false
      }
    }
  },
  admin: {
    dashboard: {
      path: "/tableau-de-bord",
      component: DashboardPage,
      text: "Tableau de bord",
      icon: "dashboard",
      hideInMenu: false
    },
    conso: {
      path: "/info-conso",
      component: PlanPage,
      text: "Info conso",
      icon: "dashboard",
      hideInMenu: false
    },
    orderForms: {
      path: "/bons-de-commande",
      component: OrderFormPage,
      text: "Bons de commande",
      icon: "order",
      hideInMenu: false
    },
    estimates: {
      listing: {
        path: "/devis",
        component: EstimatesPage,
        text: "Devis",
        icon: "order",
        hideInMenu: false
      }
    },
    signatures: {
      listing: {
        path: "/signatures",
        component: SignaturesPage,
        text: "Signatures PDF",
        icon: "order",
        hideInMenu: false
      },
      creationForm: {
        path: "/nouvelle-signature",
        component: SignaturePage,
        text: "Nouvelle signature",
        icon: "order",
        hideInMenu: false
      },
      editionForm: {
        path: "/signatures/:signatureId",
        component: SignaturePage,
        text: "Modification signature",
        icon: "order",
        hideInMenu: false
      }
    },
    invoices: {
      listing: {
        path: "/factures",
        component: InvoicesPage,
        text: "Factures",
        icon: "order",
        hideInMenu: false
      },
      creationForm: {
        path: "/nouvelle-facture",
        component: InvoicePage,
        text: "Nouvelle facture",
        icon: "order",
        hideInMenu: false
      },
      editionForm: {
        path: "/modification-facture/:invoiceId",
        component: InvoicePage,
        text: "Modification facture",
        icon: "order",
        hideInMenu: false
      }
    },
    payments: {
      listing: {
        path: "/paiements",
        component: PaymentsPage,
        text: "Paiements",
        icon: "order",
        hideInMenu: false
      }
    },
    sellers: {
      path: "/utilisateurs",
      component: SellerPage,
      text: "Mes utilisateurs",
      icon: "sellers",
      hideInMenu: false
    },
    profiles: {
      path: "/profils",
      component: ProfilePage,
      text: "Mes profils utilisateurs",
      icon: "sellers",
      hideInMenu: false
    },
    products: {
      path: "/catalogue",
      component: ProductListingPage,
      text: "Catalogue produits",
      icon: "products",
      hideInMenu: false
    },
    productCategories: {
      path: "/categories",
      component: ProductCategoryListingPage,
      text: "Catégories produits",
      icon: "products-categories",
      hideInMenu: false
    },
    settings: {
      general: {
        path: "/parametres/general",
        component: Info,
        text: "Général",
        hideInMenu: false
      },
      terms: {
        path: "/parametres/CGV",
        component: GeneralTerms,
        text: "CGV",
        hideInMenu: false
      },
      withdrawal: {
        path: "/parametres/retractation",
        component: Withdrawal,
        text: "Rétractation",
        hideInMenu: false
      },
      colors: {
        path: "/parametres/couleurs",
        component: ColorCompany,
        text: "Couleurs",
        hideInMenu: false
      },
      logos: {
        path: "/parametres/logos",
        component: Logos,
        text: "Logos",
        hideInMenu: false
      },
      payment: {
        path: "/parametres/paiement",
        component: PaymentPage,
        text: "Paiement",
        hideInMenu: false
      },
      paymentVariant: {
        path: "/parametres/paiement/variant/:variantType",
        component: PaymentVariant,
        text: "Paiement",
        hideInMenu: false
      },
      paymentSettings: {
        path: "/parametres/paiement/common",
        component: PaymentSettings,
        text: "Paiement",
        hideInMenu: false
      }
      // financing: {
      //   path: "/parametres/financement",
      //   component: Financing,
      //   text: "Paiement",
      //   hideInMenu: false
      // }
    }
  }
};

export const superAdmin = {
  hiddenAdmin: {
    path: "/superosbg",
    component: SuperAdminHiddenPage,
    text: "Admin",
    breadText: "Accueil extra BG",
    icon: "lock",
    hideInMenu: true
  },
  uploadCatalogAdmin: {
    path: "/superosbg/upload-catalog",
    component: SuperAdminImportCatalogPage,
    text: "Admin",
    breadText: "Import d'un catalogue",
    icon: "lock",
    hideInMenu: true
  },
  uploadClientsAdmin: {
    path: "/superosbg/upload-clients",
    component: SuperAdminImportClientsPage,
    text: "Admin",
    breadText: "Import d'un annuaire",
    icon: "lock",
    hideInMenu: true
  },
  replicateCatalogAdmin: {
    path: "/superosbg/replicate-catalog",
    component: SuperAdminReplicateCatalogPage,
    text: "Admin",
    breadText: "Duplication d'un catalogue",
    icon: "lock",
    hideInMenu: true
  },
  deleteCatalogAdmin: {
    path: "/superosbg/delete-catalog",
    component: SuperAdminDeleteCatalogPage,
    text: "Admin",
    breadText: "Suppression d'un catalogue",
    icon: "lock",
    hideInMenu: true
  },
  admin: {
    path: "/superbg",
    component: SuperAdminHomePage,
    text: "Admin",
    breadText: "Accueil BG",
    icon: "lock",
    hideInMenu: false
  },
  adminCompanies: {
    path: "/superbg/entreprises",
    component: SuperAdminCompaniesPage,
    text: "Entreprises",
    breadText: "Entreprises",
    icon: "lock",
    hideInMenu: true
  },
  adminViewCompany: {
    path: "/superbg/entreprises/details/:companyId",
    component: SuperAdminManageCompanyPage,
    text: "Détails",
    breadText: ":companyId",
    icon: "lock",
    hideInMenu: true
  },
  adminAddUserToCompany: {
    path: "/superbg/entreprises/details/:companyId/nouvel-utilisateur",
    component: SuperAdminUserPage,
    text: "Modification",
    breadText: "Nouvel utlisateur",
    icon: "lock",
    hideInMenu: true
  },
  adminEditCompany: {
    path: "/superbg/entreprises/details/:companyId/modification",
    component: SuperAdminEditCompanyPage,
    text: "Modification",
    breadText: "Modification",
    icon: "lock",
    hideInMenu: true
  },
  adminCreateCompany: {
    path: "/superbg/entreprises/creation",
    component: SuperAdminAddCompanyPage,
    text: "Admin",
    breadText: "Création",
    icon: "lock",
    hideInMenu: true
  },
  adminUsers: {
    path: "/superbg/utilisateurs",
    component: SuperAdminUsersPage,
    text: "Admin",
    breadText: "Utilisateurs",
    icon: "lock",
    hideInMenu: true
  },
  adminCreateUser: {
    path: "/superbg/utilisateurs/creation",
    component: SuperAdminUserPage,
    text: "Admin",
    breadText: "Création",
    icon: "lock",
    hideInMenu: true
  },
  adminLog: {
    path: "/superbg/logs",
    component: SuperAdminLogsPage,
    text: "Admin",
    breadText: "Logs",
    icon: "lock",
    hideInMenu: true
  }
};
